import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import TOC from "components/toc";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/notepc"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/cpunotepc.png"}) { ...eyecatchImg },
    allNotepcJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ノートパソコン用CPU性能比較表・選び方`}</h1>
    <time itemProp="datePublished" dateTime="Sat Oct 12 2024 00:56:48 GMT+0900 (日本標準時)">更新日:2024年10月12日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ノートパソコン用CPU性能比較" mdxType="Image" />
    <p>{`CPUはパソコン作業の快適さを決める上で最も重要なパーツであるが、選ぶのも非常に難しい。`}</p>
    <ul>
      <li>「同じCore i5なのに<b>複数の種類</b>があり、どれを選べば良いのか分からない」</li>
      <li>「自分にあったCPUを探しているが、<b>後ろに数字や記号</b>が付いていて何をどう比較すれば良いのやら」</li>
    </ul>
    <p>{`と思う人は多いはず。`}</p>
    <p>{`実際私も最初に購入したノートパソコンはCore i5のCPUが搭載されていて、`}<em parentName="p">{`「Core i5なら大丈夫だろう！」`}</em>{`と思っていたが２世代前(=最新から２年遅れた)のCPUであった事が判明して、十分な速度を得られぬまま次の買替まで我慢したという経験がある。`}</p>
    <p>{`このようなミスを避け、初心者であっても`}<em parentName="p">{`自分にあったノートパソコン向けCPUを納得して決められるようにする`}</em>{`ことが本ページのゴールである。デスクトップを含めてCPUを比較したい場合は本家`}<a href="/cpu/">{`CPU性能比較表`}</a>{`を参照してほしい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "型番の読み方を知り、大まかな性能と用途を明確にする",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%9E%8B%E7%95%AA%E3%81%AE%E8%AA%AD%E3%81%BF%E6%96%B9%E3%82%92%E7%9F%A5%E3%82%8A%E3%80%81%E5%A4%A7%E3%81%BE%E3%81%8B%E3%81%AA%E6%80%A7%E8%83%BD%E3%81%A8%E7%94%A8%E9%80%94%E3%82%92%E6%98%8E%E7%A2%BA%E3%81%AB%E3%81%99%E3%82%8B",
        "aria-label": "型番の読み方を知り、大まかな性能と用途を明確にする permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`型番の読み方を知り、大まかな性能と用途を明確にする`}</h2>
    <p>{`CPU選びの基本はまず型番を理解すること。すなわちCore i5 1360Pならば、それぞれの数値や記号が何を表しているのかを理解することである。`}</p>
    <p>{`型番を読み解くことで`}<em parentName="p">{`どちらのCPUが性能が高い`}</em>{`か？`}<em parentName="p">{`どういった用途で開発されたCPUか`}</em>{`ということが分かるようになる。`}</p>
    <p><em parentName="p">{`型番はシリーズ、世代、マイナーモデルナンバー、末尾の文字に分解できる`}</em>{`が、それぞれについて解説していく。`}</p>
    <h3 {...{
      "id": "シリーズ-－-価格帯別CPUの種類",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA-%EF%BC%8D-%E4%BE%A1%E6%A0%BC%E5%B8%AF%E5%88%A5CPU%E3%81%AE%E7%A8%AE%E9%A1%9E",
        "aria-label": "シリーズ － 価格帯別CPUの種類 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`シリーズ － 価格帯別CPUの種類`}</h3>
    <p>{`パソコン用のCPUを販売している会社はインテルとAMDという２社しかない。インテルは主にCore iシリーズ、AMDはRyzenというシリーズがそれぞれ主力である。`}</p>
    <p>{`価格帯別にCPUのシリーズを並べてみた。上に書いてあるものほど高性能なCPUである。`}</p>
    <BasicTable className="nb center" mdxType="BasicTable">
  <caption><em>CPUのシリーズ</em></caption>
  <tr>
    <th></th>
    <th>Intel</th>
    <th>AMD</th>
  </tr>
  <tr>
    <td rowSpan="2">ハイエンド</td>
    <td>Core i9</td>
    <td>Ryzen 9</td>
  </tr>
  <tr>
    <td>Core i7</td>
    <td>Ryzen 7</td>
  </tr>
  <tr>
    <td rowSpan="2">ミドルエンド</td>
    <td>Core i5</td>
    <td>Ryzen 5</td>
  </tr>
  <tr>
    <td>Core i3</td>
    <td>Ryzen 3</td>
  </tr>
  <tr>
      <td rowSpan="2">ローエンド</td>
      <td>Pentium</td>
      <td>Athlon</td>
  </tr>
  <tr>
    <td>Celeron</td>
    <td>A12,A10,A8,A6</td>
  </tr>
    </BasicTable>
    <p>{`またIntelの新世代CPUとして`}<em parentName="p">{`Core Ultra 9`}</em>{`, `}<em parentName="p">{`Core Ultra 7`}</em>{`, `}<em parentName="p">{`Core Ultra 5`}</em>{`が発売されておりiが消えているが、シリーズの付け方としてはCore iシリーズと同等である。`}</p>
    <h3 {...{
      "id": "世代-－-年単位の大きな改善",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%B8%96%E4%BB%A3-%EF%BC%8D-%E5%B9%B4%E5%8D%98%E4%BD%8D%E3%81%AE%E5%A4%A7%E3%81%8D%E3%81%AA%E6%94%B9%E5%96%84",
        "aria-label": "世代 － 年単位の大きな改善 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`世代 － 年単位の大きな改善`}</h3>
    <p>{`CPUはおおよそ年単位で大規模にアーキテクチャ(設計)が改善され、新モデルとして発売される。この年単位の改善ごとにCPUの世代が上がっていく。`}</p>
    <p>{`つまり世代はほぼ毎年改善されるCPUアーキテクチャが現在何代目かを示し、`}<em parentName="p">{`値が大きいほど最新で高性能`}</em>{`である。`}</p>
    <p>{`ただし`}<em parentName="p">{`Core Ultraなどiが名称に含まれないものはiシリーズよりも常に新しい`}</em>{`ことに注意したい。`}</p>
    <p>{`世代はCPUの型番で、シリーズ名に続く数字の最初の１文字、あるいは２文字で表される、例を上げると次のとおりである。`}</p>
    <AccentBox title="Coreシリーズ/Ryzenの世代の例" mdxType="AccentBox">
  <li>Core i7 <b style={{
          color: 'red'
        }}>9</b>750H・・・<b style={{
          color: 'red'
        }}>第９世代</b>Intel CPU ゲーミングノート向け</li>
  <li>Core i5 <b style={{
          color: 'red'
        }}>12</b>55U・・・<b style={{
          color: 'red'
        }}>第12世代</b>Intel CPU、低消費電力モデル</li>
  <li>Core i5 <b style={{
          color: 'red'
        }}>13</b>60P・・・<b style={{
          color: 'red'
        }}>第13世代</b>Intel CPU、グラフィック強化版</li>
  <li>Core Ultra 9 <b style={{
          color: 'red'
        }}>1</b>85H・・・<b style={{
          color: 'red'
        }}>新第1世代</b>Intel CPU、ハイエンドモデル</li>
  <li>Ryzen 7 <b style={{
          color: 'red'
        }}>7</b>736U・・・<b style={{
          color: 'red'
        }}>第7世代</b>AMD CPU、低消費電力版</li>
    </AccentBox>
    <h3 {...{
      "id": "マイナーモデルナンバー-－-地味な改善",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%BC%E3%83%A2%E3%83%87%E3%83%AB%E3%83%8A%E3%83%B3%E3%83%90%E3%83%BC-%EF%BC%8D-%E5%9C%B0%E5%91%B3%E3%81%AA%E6%94%B9%E5%96%84",
        "aria-label": "マイナーモデルナンバー － 地味な改善 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マイナーモデルナンバー　－　地味な改善`}</h3>
    <p>{`シリーズ名に続く数字のお尻３文字がマイナーモデルナンバーである。世代間ほどの違いはないものの、`}<em parentName="p">{`モデルナンバーが大きいと性能が多少アップ`}</em>{`する。`}</p>
    <p>{`たとえば13`}<em parentName="p">{`40`}</em>{`Pと比べると13`}<em parentName="p">{`50`}</em>{`Pは僅かながら性能がアップしている。ただ、後述するベンチマークでも差があまり見られないものは多く、場合によっては統計的な不正確さによりモデルナンバーの数値が大きいものが小さいものに負けていることもある。`}</p>
    <p>{`少なくとも大きな違いは無いことは見て取れるだろう。`}</p>
    <h3 {...{
      "id": "末尾の意味-－-CPUの用途等を表す文字",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%AB%E5%B0%BE%E3%81%AE%E6%84%8F%E5%91%B3-%EF%BC%8D-CPU%E3%81%AE%E7%94%A8%E9%80%94%E7%AD%89%E3%82%92%E8%A1%A8%E3%81%99%E6%96%87%E5%AD%97",
        "aria-label": "末尾の意味 － CPUの用途等を表す文字 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`末尾の意味 － CPUの用途等を表す文字`}</h3>
    <p>{`最後に末尾のアルファベット文字はCPUの用途を表す、これを見ると`}<em parentName="p">{`パソコンがどのような特徴を備えているのかすぐに分かる`}</em>{`。Intel、AMDの末尾の意味は下記のようになる。まずはIntelから。`}</p>
    <AccentBox title="Intel CPUの末尾文字" mdxType="AccentBox">
  <li><span className="bold">HK</span>・・・ハイパフォーマンス、限界を超えて性能を出せるオーバークロックに対応</li>
  <li><span className="bold">H</span>・・・ハイパフォーマンス、<em>ゲーミングノートに主に使われる</em></li>
  <li><span className="bold">P</span>・・・ハイパフォーマンスと低消費電力を両立したバランスの良いモデルで12世代から登場</li>
  <li><span className="bold">G</span>・・・内蔵グラフィック強化版・Adobeなどの<em>動画像編集やゲームで有利</em></li>
  <li><span className="bold">T</span>・・・低消費電力版、ノートパソコンや小型デスクトップに使われる、スペック高め</li>
  <li><span className="bold">U</span>・・・15Wウルトラ低消費電力版、事務作業に最適で<em>バッテリー寿命も長い</em></li>
  <li><span className="bold">Y</span>・・・5Wの超・超・低消費電力版。<em>パワーは弱い</em>、ライトユーザ向け</li>
    </AccentBox>
    <p>{`次にAMD、Intelとよく似た感じである。`}</p>
    <AccentBox title="AMD CPUの末尾文字" mdxType="AccentBox">
  <li><span className="bold">H</span>・・・ノート用ハイパフォーマンス Intelと同様</li>
  <li><span className="bold">GE</span>・・・内蔵グラフィック強化版、低消費電力版</li>
  <li><span className="bold">U</span>・・・ウルトラ低消費電力版、Intelと同様</li>
    </AccentBox>
    <p>{`この表を俯瞰することで、「Excelなど事務作業中心だからHは向いていないな」とか、「このCPUならバッテリー寿命は長そうだな」など判断の基準ができることだろう。`}</p>
    <h2 {...{
      "id": "ノートパソコン向けCPU性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%90%91%E3%81%91CPU%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "ノートパソコン向けCPU性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコン向けCPU性能比較表`}</h2>
    <p>{`型番の読み方は分かったと思う。しかし型番を理解しただけでは比較対象のCPUで、どちらがどれぐらい能力が高いかという`}<em parentName="p">{`性能差の大きさ`}</em>{`は分からない。`}</p>
    <p>{`そこで`}<em parentName="p">{`ベンチマークというCPUの絶対性能を数値化してくれる物差し`}</em>{`が複数社で提供されている。`}</p>
    <p>{`ベンチマークだけを見れば良いのではと思うかもしれないが、ベンチマークの統計的な信頼性はやや低く、また、測る指標が自分の用途と完全に一致するわけではない。`}</p>
    <p>{`よって`}<em parentName="p">{`型番とベンチマークを両方見て、自分にあったCPUを見つける`}</em>{`ことが最良の選択肢となるだろう。`}</p>
    <p>{`これから紹介するCPU性能比較表の補足事項を下記にまとめておいたので、表の意味が良く分からない人は参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <TableDescription mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <p>{`ベンチマークとして総合的な能力を計測するPassMarkの総合スコアPassMark(M)とシングルスレッドの性能を測るPassMark(S)を掲載している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`基本的にはPassMark(M)を下記目安とともに参考にすれば良い`}</em></strong>{`だろう。ただ、PASSMARKの値はベンチマークバージョンにより上下するため、他サイトのスコアは参考にならないので注意しておこう。`}</p>
    <p>{`下記は当サイトでのPassMarkの目安である。これを参考にしておすすめのCPUを選んで欲しい。`}</p>
    <PassMark mdxType="PassMark" />
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'celeron', 'apple']} cpuData={props.data.allNotepcJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      